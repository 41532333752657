.groupTitle {
  color: #3e3e3e;
  background: #efefef;
  padding: 5px;
}

.groupEntry {
  margin-bottom: 10px;
}

.note p:first-child {
  margin-top: 0;
}