.root {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 90%;
    background: white;

    box-shadow: 1px 1px 4px 0 rgba(0,0,0,.1);
    overflow: scroll;
}

.rightBar {

}

.inset {
    padding: 20px;
}

.deletedFlag {
    color: white;
    padding: 10px;
    background: #F44336;
    margin-bottom: 20px;
}

.rootWrapper {
    /** Split view **/
    display: flex;
    flex-direction: row;
}


.notesSidebar {
    max-width: 500px;
    min-width: 250px;
    margin-left: 20px;
    border-left: 1px solid silver;
    padding-left: 20px;
    flex: 1;
}

.isDeleted .rootWrapper {
    opacity: .8;
}

.splitViewMainSection {
    flex: 4;
}

.title {
    margin-bottom: 1em;
    font-size: 1.5em;
    font-weight: bold;
    display: flex;
}

.wordType {
    color: gray;
    font-size: 0.8em;
    padding-right: .5em;
}

.transliteration {
    font-size: 0.8em;
    padding-left: .5em;
}

.description {
    color: gray;
    font-size: .8em;
    padding-left: 10px;
}

.icon {
    margin-right: .2em;
}

.main {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
}

.controls {
    display: flex;
    flex-direction: row;
    align-items: center;
}