.item {
    display: flex;
    align-content: center;
    flex-direction: row;

    height: 180px;

    border-bottom: 1px solid silver;
}

.item > * {
    flex: 1;
}

.complete  {
    background: #e9fcd4;
}
.failed  {
    background: #fcd4d4;
}
.current {
    background: #e3e3e3 !important;
}

.ToBeRecordedInfo {
    padding: 20px;
    font-size: 25px;

    display: flex;
    flex-direction: column;
    justify-content: center;
}
.ToBeRecordedInfo .wordId {
    color: gray;
    font-size: .5em;
}
.ToBeRecordedInfo .wordType {
    color: #7d7d7d;
    text-transform: uppercase;
    font-size: 0.8em;
}
.ToBeRecordedInfo .wordWithVowels {
    margin-left: 0.3em;
    font-size: 1.5em;
    font-weight: bold;
}
.ToBeRecordedInfo .translit {
    color: #7d7d7d;
    font-size: 0.7em;
}
.ToBeRecordedInfo .spinner {
    margin-top: 8px;
}
.ToBeRecordedInfo :global .ms-Spinner {
    display: inline-block;
}

.RightColumn {
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.RecorderControls {
    display: flex;
    align-items: center;
}


.completeMarker {
    color: #4CAF50;
    font-weight: bold;
    margin-left: 10px;
}
.failedMarker {
    color: #f44336;
    font-weight: bold;
    margin-left: 10px;
}

.existingRecording {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid silver;
    margin-right: 10px;
}
.rejectedRecording {
    text-decoration: line-through;
    color: silver;
}