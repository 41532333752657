.table {
  margin-top: 10px;
  width: 100%;
}

.table td, .table th {
  padding: 0 .4em;
}

.table tbody tr:nth-child(odd) {
  background: #e8eaf6;
}

.table tbody tr:hover {
  background: #ffd54f;
}

.icon {
  margin-right: .2em;
}

.wordType {
  color: gray;
  font-size: 0.8em;
  padding-left: .5em;
}

.transliteration {
  font-size: 0.8em;
  padding-left: .5em;
}