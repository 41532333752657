.root {
    margin-top: 4em;
}

.occurrenceGroup
{
    margin-top: 10px;
}

.groupTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.groupTitle strong {
    color: gray;
    font-size: 12px;
}

.groupTitle .actions {
    padding-left: 1em;
}

.occurrence {
    min-height: 12px;
    margin: 1em 0;

    display: flex;
    flex-direction: row;
}