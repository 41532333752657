.wordType {
    color: gray;
    font-size: 0.8em;
    padding-right: .5em;
}

.transliteration {
    font-size: 0.8em;
    padding-left: .5em;
}

.description {
    color: gray;
    font-size: .8em;
    padding-left: 10px;
}

.icon {
    margin-right: .2em;
}