.root {
    border-bottom: 1px solid silver;
    padding: 20px;

    display: flex;
    flex-direction: row;
}

.title {
    flex: 1;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.number {
    background: #cccccc;
    border-radius: 0.8em;
    color: #ffffff;
    display: inline-block;
    font-weight: bold;
    line-height: 1.6em;
    margin-right: 5px;
    text-align: center;
    width: 1.6em;
}

.grid {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 10px;
    margin-top: 20px;
    max-width: 600px;
}

.label {
    font-weight: bold;
    padding-top: 0.5em;
}

.wordsArray :global(.ms-TextField) {
    flex: 1;
    max-width: 400px;
}