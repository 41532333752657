.root {
    margin: 30px;
    color: white;
    text-align: center;
    font-size: 30px;
}

.letter {
    font-size: 3em;
}


.header {
    composes: letter;
    border-bottom: 1px solid white;
}

.table {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-column-gap: 2em;
    grid-row-gap: 1em;
}
.table > * {
    text-align: center;
}

.label {
    grid-row: 2;
    font-size: .5em;
}

.value {
    composes: letter;
    grid-row: 1;
    line-height: 1;
}

.final {
    grid-column: 1;
}
.medial {
    grid-column: 2;
}
.initial {
    grid-column: 3;
}