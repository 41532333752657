.note {
    border: 1px solid #9E9E9E;
    margin: 20px 0;
    border-radius: 2px;
}

.header {
    padding: 5px;
    background: #ececec;
}

.content {
    padding: 5px;
}

.mark {
    padding: 0.3em;
    border-radius: 3px;
    color: white;
    display: inline-block;
    margin-top: 3px;
    margin-right: 5px;
}

.resolved {
    background: #3f51b5;
}

.open {
    background: #4CAF50;
}